import CheckContext from 'src/components/CheckContext';
import { ContentsBox } from 'src/components/ContentsBox/index';
import { ContentsFrame } from 'src/components/ContentsFrame';
import PageBackground from 'src/components/PageBackground';
import PartnerCard from 'src/components/PartnerCard';
import Spacer from 'src/components/Spacer';
import { useTranslation } from 'src/hooks/useTransaction';

import ContactUs from './components/ContactUs';
import TeamCard from './components/TeamCard/index';
import Introduction from '../../components/Introduction/index';
import { useIsDesktop } from '../../hooks/useIsDesktop';

export default function About() {
  const { t, language } = useTranslation();
  const isDesktop = useIsDesktop();
  return (
    <PageBackground page="About">
      <ContentsFrame type="main">
        <Spacer space={280} mSpace={100} />
        <div data-anchor="whatWeDo" />
        <Introduction titleType="h1" descType="b1" color="black" desc={t('pages.About.index.Building desc')} descWidth="1140px" gap={30} mGap={14}>
          <strong data-color="red">{t('pages.About.index.Building')} </strong>
          {t('pages.About.index.Web3 Scalability')}
        </Introduction>
        <Spacer space={140} mSpace={40} />
        <ContentsBox
          topPadding={80}
          mTopPadding={40}
          rightPadding={64}
          bottomPadding={80}
          context={
            <>
              <Spacer space={16} mSpace={0} />
              <CheckContext
                maxWidth="950px"
                contents={[
                  {
                    title: t('pages.About.index.Cosmostation Validator'),
                    contents: t('pages.About.index.Cosmostation Validator desc'),
                  },
                  {
                    title:
                      language === 'ko' ? (
                        isDesktop ? (
                          <>{t('pages.About.index.Blockchain Infrastructure')}</>
                        ) : (
                          <>
                            {t('pages.About.index.Blockchain Infrastructure1')}
                            <br />
                            {t('pages.About.index.Blockchain Infrastructure2')}
                          </>
                        )
                      ) : (
                        t('pages.About.index.Blockchain Infrastructure')
                      ),
                    contents: t('pages.About.index.Blockchain Infrastructure desc'),
                  },
                  {
                    title: t('pages.About.index.Ventures'),
                    contents: t('pages.About.index.Ventures desc'),
                  },
                ]}
              />
            </>
          }
        />
        <Spacer space={240} mSpace={60} />
        <div data-anchor="ourPartners" />
        <PartnerCard />
        <Spacer space={240} mSpace={60} />
        <div data-anchor="contactUs" />
        <ContactUs />
        <Spacer space={285} mSpace={100} />
      </ContentsFrame>
    </PageBackground>
  );
}
